import React, { useState, useEffect } from 'react';
import { getVehicles } from '../../routes/api';
import { Bar, Pie } from 'react-chartjs-2';
import { TailSpin } from 'react-loader-spinner';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const VehicleStatsChart = () => {
    const [vehicleStats, setVehicleStats] = useState({
        types: [],
        typesCount: [],
        statuses: [],
        statusCount: [],
        averagePricePerType: [],
        locations: [],
        locationCount: [],
        newVehiclesDates: [],
        newVehiclesCount: []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                let page = 1;
                let allVehicles = [];
                let moreVehiclesAvailable = true;

                while (moreVehiclesAvailable) {
                    const response = await getVehicles(page, 10000000);
                    const vehicles = response.data;

                    if (vehicles.length === 0) {
                        moreVehiclesAvailable = false;
                    } else {
                        allVehicles = [...allVehicles, ...vehicles];
                        page += 1;
                    }
                }

                const typeStats = {};
                const statusStats = {};
                const locationStats = {};
                const pricePerTypeStats = {};
                const newVehiclesStats = {};

                allVehicles.forEach((vehicle) => {
                    const type = vehicle.type;
                    const status = vehicle.status;
                    const location = vehicle.location;
                    const price = parseFloat(vehicle.price_per_day);
                    const createdAt = vehicle.created_at.split('T')[0];

                    typeStats[type] = (typeStats[type] || 0) + 1;

                    statusStats[status] = (statusStats[status] || 0) + 1;

                    locationStats[location] = (locationStats[location] || 0) + 1;

                    if (!pricePerTypeStats[type]) {
                        pricePerTypeStats[type] = { total: 0, count: 0 };
                    }
                    pricePerTypeStats[type].total += price;
                    pricePerTypeStats[type].count += 1;

                    newVehiclesStats[createdAt] = (newVehiclesStats[createdAt] || 0) + 1;
                });

                const types = Object.keys(typeStats);
                const typesCount = Object.values(typeStats);

                const statuses = Object.keys(statusStats);
                const statusCount = Object.values(statusStats);

                const locations = Object.keys(locationStats);
                const locationCount = Object.values(locationStats);

                const averagePricePerType = types.map((type) => (pricePerTypeStats[type].total / pricePerTypeStats[type].count).toFixed(2));

                const sortedDates = Object.keys(newVehiclesStats).sort();
                const newVehiclesCount = sortedDates.map((date) => newVehiclesStats[date]);

                setVehicleStats({
                    types,
                    typesCount,
                    statuses,
                    statusCount,
                    averagePricePerType,
                    locations,
                    locationCount,
                    newVehiclesDates: sortedDates,
                    newVehiclesCount
                });
            } catch (error) {
                console.error('Error fetching vehicle stats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicles();
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <TailSpin height="80" width="80" color="#1976d2" ariaLabel="loading" />
            </div>
        );
    }

    const typeData = {
        labels: vehicleStats.types,
        datasets: [
            {
                label: 'Vehicle Types',
                data: vehicleStats.typesCount,
                backgroundColor: ['#1976d2', '#ff9800', '#4caf50', '#f44336'],
            },
        ],
    };

    const statusData = {
        labels: vehicleStats.statuses,
        datasets: [
            {
                label: 'Vehicle Statuses',
                data: vehicleStats.statusCount,
                backgroundColor: ['#4caf50', '#ff9800', '#f44336'],
            },
        ],
    };

    const locationData = {
        labels: vehicleStats.locations,
        datasets: [
            {
                label: 'Vehicles by Location',
                data: vehicleStats.locationCount,
                backgroundColor: '#1976d2',
            },
        ],
    };

    const averagePriceData = {
        labels: vehicleStats.types,
        datasets: [
            {
                label: 'Average Price per Day ($)',
                data: vehicleStats.averagePricePerType,
                backgroundColor: '#ff9800',
            },
        ],
    };

    const newVehiclesData = {
        labels: vehicleStats.newVehiclesDates,
        datasets: [
            {
                label: 'New Vehicles',
                data: vehicleStats.newVehiclesCount,
                backgroundColor: '#1976d2',
            },
        ],
    };

    const barOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Vehicle Statistics',
            },
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Category',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Count',
                },
                min: 0,
            },
        },
    };

    const pieOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', width: '100%' }}>
            <div style={{ width: '30%', height: 'auto' }}>
                <Pie data={typeData} options={pieOptions} />
            </div>
            <div style={{ width: '30%', height: 'auto' }}>
                <Pie data={statusData} options={pieOptions} />
            </div>
            <div style={{ width: '30%', height: 'auto' }}>
                <Bar data={locationData} options={barOptions} />
            </div>
        </div>
    );
};

export default VehicleStatsChart;

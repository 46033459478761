import { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { Card, CardContent, TextField, Button, Avatar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import './MyLoginPage.css';

const MyLoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = e => {
        e.preventDefault();
        login({ email, password })
            .then(() => notify('Login successful', { type: 'info' }))
            .catch(error => {
                notify(error.message, { type: 'warning' });
            });
    };

    return (
        <div className="login-page">
            <Card className="login-card">
                <Avatar className="lock-icon">
                    <LockIcon />
                </Avatar>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Email"
                            type="email"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="submit-button"
                        >
                            Sign In
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default MyLoginPage;

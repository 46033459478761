import { getUsers, getUserById, createUser, updateUser, deleteUser } from '../routes/api';
import { getVehicles, getVehicleById, createVehicle, updateVehicle, deleteVehicle } from '../routes/api';
import { getReservations, getReservationById, createReservation, updateReservation, deleteReservation } from '../routes/api';

const dataProvider = {
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { q } = params.filter;

        let response;

        if (resource === 'users') {
            response = await getUsers(page, perPage, q);
            return {
                data: response.data,
                total: response.total,
            };
        }

        if (resource === 'vehicles') {
            response = await getVehicles(page, perPage, q);
            return {
                data: response.data,
                total: response.total,
            };
        }

        if (resource === 'reservations') {
            response = await getReservations(page, perPage, q);
            return {
                data: response.data,
                total: response.total,
            };
        }
        
        throw new Error(`Unknown resource: ${resource}`);
    },
    getOne: async (resource, params) => {
        if (resource === 'users') {
            const response = await getUserById(params.id);
            return {
                data: response,
            };
        }
        if (resource === 'vehicles') {
            const response = await getVehicleById(params.id);
            return {
                data: response,
            };
        }
        if (resource === 'reservations') {
            const response = await getReservationById(params.id);
            return {
                data: response,
            };
        }
        throw new Error(`Unknown resource: ${resource}`);
    },
    create: async (resource, params) => {
        if (resource === 'users') {
            const response = await createUser(params.data);
            return {
                data: response,
            };
        }
        if (resource === 'vehicles') {
            const response = await createVehicle(params.data);
            return {
                data: response,
            };
        }
        if (resource === 'reservations') {
            const response = await createReservation(params.data);
            return {
                data: response,
            };
        }
        throw new Error(`Unknown resource: ${resource}`);
    },
    update: async (resource, params) => {
        if (resource === 'users') {
            const response = await updateUser(params.id, params.data);
            return {
                data: response,
            };
        }
        if (resource === 'vehicles') {
            const response = await updateVehicle(params.id, params.data);
            return {
                data: response,
            };
        }
        if (resource === 'reservations') {
            const response = await updateReservation(params.id, params.data);
            return {
                data: response,
            };
        }
        throw new Error(`Unknown resource: ${resource}`);
    },
    delete: async (resource, params) => {
        if (resource === 'users') {
            await deleteUser(params.id);
            return { data: params.previousData };
        }
        if (resource === 'vehicles') {
            await deleteVehicle(params.id);
            return { data: params.previousData };
        }
        if (resource === 'reservations') {
            await deleteReservation(params.id);
            return { data: params.previousData };
        }
        throw new Error(`Unknown resource: ${resource}`);
    },
};

export default dataProvider;

import React, { useState, useEffect } from 'react';
import { getReservations } from '../../routes/api';
import { Bar } from 'react-chartjs-2';
import { TailSpin } from 'react-loader-spinner';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ReservationStatsChart = () => {
    const [reservationStats, setReservationStats] = useState({
        dates: [],
        confirmedReservations: [],
        totalIncome: [],
        vehiclesRented: []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReservations = async () => {
            try {
                let page = 1;
                let allReservations = [];
                let moreReservationsAvailable = true;

                while (moreReservationsAvailable) {
                    const response = await getReservations(page, 10000000);
                    const reservations = response.data;

                    if (reservations.length === 0) {
                        moreReservationsAvailable = false;
                    } else {
                        allReservations = [...allReservations, ...reservations];
                        page += 1;
                    }
                }

                const dailyStats = {};
                const totalIncomeStats = {};
                const vehicleRentalStats = {};

                allReservations.forEach((reservation) => {
                    const startDate = reservation.start_date.split(' ')[0];

                    // Count confirmed reservations by start date
                    if (reservation.status === 'confirmed') {
                        dailyStats[startDate] = (dailyStats[startDate] || 0) + 1;
                    }

                    // Accumulate total income by reservation start date
                    totalIncomeStats[startDate] = (totalIncomeStats[startDate] || 0) + parseFloat(reservation.total_price);

                    // Count vehicles rented by start date
                    vehicleRentalStats[startDate] = (vehicleRentalStats[startDate] || 0) + 1;
                });

                const sortedDates = Object.keys(dailyStats).sort();

                const confirmedReservationsCount = sortedDates.map((date) => dailyStats[date] || 0);
                const totalIncomeCount = sortedDates.map((date) => totalIncomeStats[date] || 0);
                const vehiclesRentedCount = sortedDates.map((date) => vehicleRentalStats[date] || 0);

                setReservationStats({
                    dates: sortedDates,
                    confirmedReservations: confirmedReservationsCount,
                    totalIncome: totalIncomeCount,
                    vehiclesRented: vehiclesRentedCount
                });
            } catch (error) {
                console.error('Error fetching reservation stats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReservations();
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <TailSpin height="80" width="80" color="#1976d2" ariaLabel="loading" />
            </div>
        );
    }

    const data = {
        labels: reservationStats.dates,
        datasets: [
            {
                label: 'Confirmed Reservations',
                data: reservationStats.confirmedReservations,
                backgroundColor: '#1976d2',
            },
            {
                label: 'Total Income ($)',
                data: reservationStats.totalIncome,
                backgroundColor: '#4caf50',
            },
            {
                label: 'Vehicles Rented',
                data: reservationStats.vehiclesRented,
                backgroundColor: '#ff9800',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Reservation Statistics per Day',
            },
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Count / Income',
                },
                min: 0,
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default ReservationStatsChart;


import React, { useState, useEffect } from 'react';
import { getUsers, getVehicles, getReservations } from '../routes/api';
import { Card, CardContent, Typography, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import UserStatsChart from './charts/UserStatsChart';
import ReservationStatsChart from './charts/ReservationStatsChart';
import VehicleStatsChart from './charts/VehicleStatsChart';
import VehicleMap from './maps/VehicleMap';

const Dashboard = () => {
    const [stats, setStats] = useState({
        users_count: 0,
        vehicles_count: 0,
        reservations_count: 0,
    });
    const [loading, setLoading] = useState(true);
    const [selectedChart, setSelectedChart] = useState('users');

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const userResponse = await getUsers(1, 1);
                const vehicleResponse = await getVehicles(1, 1);
                const reservationResponse = await getReservations(1, 1);

                setStats({
                    users_count: userResponse.total,
                    vehicles_count: vehicleResponse.total,
                    reservations_count: reservationResponse.total,
                });
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    const handleChartChange = (event) => {
        setSelectedChart(event.target.value);
    };

    return (
        <div>
            <h1>Dashboard</h1>
            <p>Welcome to the Need4Rent Dashboard.</p>
            
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Users</Typography>
                            <Typography variant="h4">{stats.users_count}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Vehicles</Typography>
                            <Typography variant="h4">{stats.vehicles_count}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Reservations</Typography>
                            <Typography variant="h4">{stats.reservations_count}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="chart-select-label">Select Chart</InputLabel>
                        <Select
                            labelId="chart-select-label"
                            value={selectedChart}
                            onChange={handleChartChange}
                            displayEmpty
                        >
                            <MenuItem value="users">Users Statistics</MenuItem>
                            <MenuItem value="reservations">Reservations Statistics</MenuItem>
                            <MenuItem value="vehicles">Vehicle Map</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {selectedChart === 'users' && <UserStatsChart />}
            {selectedChart === 'reservations' && <ReservationStatsChart />}
            {selectedChart === 'vehicles' && <VehicleStatsChart />}

            <VehicleMap />
        </div>
    );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Button,
    Filter,
    TextInput,
    SimpleForm,
    FunctionField,
    useNotify
} from 'react-admin';
import { Card, CardContent, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MuiTextField } from '@mui/material';
import { getReservations, createReservation, updateReservation, deleteReservation } from '../../routes/api';

const ReservationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const ReservationList = () => {
    const notify = useNotify();
    const [reservations, setReservations] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [updatedStartDate, setUpdatedStartDate] = useState('');
    const [updatedEndDate, setUpdatedEndDate] = useState('');
    const [updatedStatus, setUpdatedStatus] = useState('');

    useEffect(() => {
        getReservations()
            .then((data) => {
                setReservations(data);
            })
            .catch((error) => {
                notify('Error fetching reservations', 'error');
            });
    }, [notify]);

    const handleCreateReservation = async (reservationData) => {
        try {
            await createReservation(reservationData);
            notify('Reservation created successfully!', 'success');
            getReservations().then((data) => {
                setReservations(data);
            });
        } catch (error) {
            notify(error.response.data.message, 'error');
        }
    };

    const handleUpdateReservation = async () => {
        if (selectedReservation) {
            try {
                const updatedData = {
                    start_date: updatedStartDate,
                    end_date: updatedEndDate,
                    status: updatedStatus,
                };
                
                await updateReservation(selectedReservation.id, updatedData);
                notify('Reservation updated successfully!', 'success');
                getReservations().then((data) => {
                    setReservations(data);
                });
                setOpenEditDialog(false);
            } catch (error) {
                notify('Error updating reservation', 'error');
            }
        }
    };

    const handleDeleteReservation = async () => {
        if (selectedReservation) {
            try {
                await deleteReservation(selectedReservation.id);
                notify('Reservation deleted successfully!', 'success');
                setOpenDeleteDialog(false);
                getReservations().then((data) => {
                    setReservations(data);
                });
            } catch (error) {
                notify('Error deleting reservation', 'error');
            }
        }
    };

    const openEditModal = (reservation) => {
        setSelectedReservation(reservation);
        setUpdatedStartDate(reservation.start_date);
        setUpdatedEndDate(reservation.end_date);
        setUpdatedStatus(reservation.status);
        setOpenEditDialog(true);
    };

    const closeEditModal = () => {
        setOpenEditDialog(false);
    };

    const openDeleteModal = (reservation) => {
        setSelectedReservation(reservation);
        setOpenDeleteDialog(true);
    };

    const closeDeleteModal = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <>
            <h1>Reservations</h1>
            <Card style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h6">Search Reservations</Typography>
                    <List filters={<ReservationFilter />}>
                        <Datagrid rowClick="edit">
                            <TextField source="id" label="ID" />
                            <TextField source="user.name" label="User" />
                            <TextField source="vehicle.model" label="Vehicle" />
                            <DateField source="start_date" label="Start Date" showTime />
                            <DateField source="end_date" label="End Date" showTime />
                            <TextField source="total_price" label="Total Price" />
                            <TextField source="status" label="Status" />

                            {/* FunctionField for Actions (Edit & Delete buttons) */}
                            <FunctionField
                                label="Actions"
                                render={(record) => (
                                    <div>
                                        <Button
                                            color="primary"
                                            size="small"
                                            style={{ marginRight: 10 }}
                                            onClick={() => openEditModal(record)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="secondary"
                                            size="small"
                                            onClick={() => openDeleteModal(record)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                )}
                            />
                        </Datagrid>
                    </List>
                </CardContent>
            </Card>

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={closeEditModal} aria-labelledby="edit-dialog-title">
                <DialogTitle id="edit-dialog-title">Edit Reservation</DialogTitle>
                <DialogContent>
                    <MuiTextField
                        margin="dense"
                        label="Start Date"
                        type="datetime-local"
                        fullWidth
                        value={updatedStartDate}
                        onChange={(e) => setUpdatedStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <MuiTextField
                        margin="dense"
                        label="End Date"
                        type="datetime-local"
                        fullWidth
                        value={updatedEndDate}
                        onChange={(e) => setUpdatedEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Status"
                        fullWidth
                        value={updatedStatus}
                        onChange={(e) => setUpdatedStatus(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateReservation} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={openDeleteDialog} onClose={closeDeleteModal} aria-labelledby="delete-dialog-title">
                <DialogTitle id="delete-dialog-title">Delete Reservation</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete this reservation?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteReservation} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Create Reservation Form */}
            <Card>
                <CardContent>
                    <Typography variant="h6">Create New Reservation</Typography>
                    <SimpleForm onSubmit={handleCreateReservation}>
                        <TextInput source="user_id" label="User ID" fullWidth />
                        <TextInput source="vehicle_id" label="Vehicle ID" fullWidth />
                        <TextInput source="start_date" label="Start Date" fullWidth />
                        <TextInput source="end_date" label="End Date" fullWidth />
                        <TextInput source="total_price" label="Total Price" fullWidth />
                        <TextInput source="status" label="Status" fullWidth />
                    </SimpleForm>
                </CardContent>
            </Card>
        </>
    );
};

export default ReservationList;


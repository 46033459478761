import React, { useState, useEffect } from 'react';
import {
    FunctionField,
    List,
    Datagrid,
    TextField,
    DateField,
    Button,
    Filter,
    TextInput,
    SimpleForm,
    useNotify
} from 'react-admin';
import { Card, CardContent, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MuiTextField } from '@mui/material';
import { getUsers, createUser, updateUser, deleteUser } from '../../routes/api';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const UserList = () => {
    const notify = useNotify();
    const [users, setUsers] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [updatedName, setUpdatedName] = useState('');
    const [updatedEmail, setUpdatedEmail] = useState('');
    const [updatedPassword, setUpdatedPassword] = useState('');

    useEffect(() => {
        getUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                notify('Error fetching users', 'error');
            });
    }, [notify]);

    const handleCreateUser = async (userData) => {
        try {
            await createUser(userData);
            notify('User created successfully!', 'success');
            getUsers().then((data) => {
                setUsers(data);
            });
        } catch (error) {
            notify(error.response.data.message, 'error');
        }
    };

    const handleUpdateUser = async () => {
        if (selectedUser) {
            try {
                const userUpdateData = {
                    name: updatedName,
                    email: updatedEmail,
                };
                if (updatedPassword) {
                    userUpdateData.password = updatedPassword;
                }
                await updateUser(selectedUser.id, userUpdateData);
                notify('User updated successfully!', 'success');
                getUsers().then((data) => {
                    setUsers(data);
                });
                setOpenEditDialog(false);
            } catch (error) {
                notify('Error updating user', 'error');
            }
        }
    };

    const handleDeleteUser = async () => {
        if (selectedUser) {
            try {
                await deleteUser(selectedUser.id);
                notify('User deleted successfully!', 'success');
                setOpenDeleteDialog(false);
                getUsers().then((data) => {
                    setUsers(data);
                });
            } catch (error) {
                notify('Error deleting user', 'error');
            }
        }
    };

    const openEditModal = (user) => {
        setSelectedUser(user);
        setUpdatedName(user.name);
        setUpdatedEmail(user.email);
        setUpdatedPassword('');
        setOpenEditDialog(true);
    };

    const closeEditModal = () => {
        setOpenEditDialog(false);
    };

    const openDeleteModal = (user) => {
        setSelectedUser(user);
        setOpenDeleteDialog(true);
    };

    const closeDeleteModal = () => {
        setOpenDeleteDialog(false);
    };

    const toggleUserBlock = async (userId, isBlocked) => {
        try {
            const updatedUserData = { is_blocked: isBlocked ? 0 : 1 };
            await updateUser(userId, updatedUserData);
            notify(`User has been ${isBlocked ? 'unblocked' : 'blocked'} successfully`, 'success');
            getUsers().then((data) => setUsers(data));
        } catch (error) {
            notify(`Failed to ${isBlocked ? 'unblock' : 'block'} user`, 'error');
        }
    };

    return (
        <>
            <h1>Users</h1>
            <Card style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h6">Search Users</Typography>
                    <List filters={<UserFilter />}>
                        <Datagrid rowClick="edit">
                            <TextField source="id" label="ID" />
                            <TextField source="name" label="Name" />
                            <TextField source="email" label="Email" />
                            <FunctionField
                                source="is_admin"
                                label="Admin"
                                render={record => `${record.is_admin ? 'Yes' : 'No'}`}
                            />
                            <DateField
                                source="created_at"
                                label="Created At"
                                showTime
                            />
                            <DateField
                                source="updated_at"
                                label="Updated At"
                                showTime
                            />
                            <FunctionField
                                label="Actions"
                                render={record => (
                                    <div>
                                        <Button
                                            color="primary"
                                            size="small"
                                            style={{ marginRight: 10 }}
                                            onClick={() => openEditModal(record)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="secondary"
                                            size="small"
                                            onClick={() => openDeleteModal(record)}
                                            style={{ marginRight: 10 }}
                                        >
                                            Delete
                                        </Button>
                                        {record.is_blocked ? (
                                            <Button
                                                color="secondary"
                                                size="small"
                                                onClick={() => toggleUserBlock(record.id, true)}
                                                style={{ marginRight: 10 }}
                                            >
                                                Unblock
                                            </Button>
                                        ) : (
                                            <Button
                                                color="secondary"
                                                size="small"
                                                onClick={() => toggleUserBlock(record.id, false)}
                                                disabled={false} // Keep this enabled when user is not blocked
                                                style={{ marginRight: 10 }}
                                            >
                                                Perma Block Acc
                                            </Button>
                                        )}
                                    </div>
                                )}
                            />
                        </Datagrid>
                    </List>
                </CardContent>
            </Card>

            <Dialog open={openEditDialog} onClose={closeEditModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
                <DialogContent>
                    <MuiTextField
                        margin="dense"
                        label="Name"
                        fullWidth
                        value={updatedName}
                        onChange={(e) => setUpdatedName(e.target.value)}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={updatedEmail}
                        onChange={(e) => setUpdatedEmail(e.target.value)}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        value={updatedPassword}
                        onChange={(e) => setUpdatedPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateUser} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeleteDialog} onClose={closeDeleteModal} aria-labelledby="delete-dialog-title">
                <DialogTitle id="delete-dialog-title">Delete User</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete this user?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteUser} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Card>
                <CardContent>
                    <Typography variant="h6">Create New User</Typography>
                    <SimpleForm onSubmit={handleCreateUser}>
                        <TextInput source="name" label="Name" fullWidth />
                        <TextInput source="email" label="Email" type="email" fullWidth />
                        <TextInput source="password" label="Password" type="password" fullWidth />
                        <TextInput source="password_confirmation" label="Confirm Password" type="password" fullWidth />
                    </SimpleForm>
                </CardContent>
            </Card>
        </>
    );
};

export default UserList;

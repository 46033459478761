import apiRequest from '../services/apiRequest';

/*           name              data                    method   route           data        auth */
export const loginUser      = (formData) => apiRequest('post', '/login',        formData        );
export const getUserProfile = (id)       => apiRequest('get',  `/users/${id}`,  {},         true);

export const getUsers = (page = 1, perPage = 10, search = '') => 
    apiRequest('get', `/admin/users?page=${page}&per_page=${perPage}${search ? `&search=${search}` : ''}`, {}, true);
export const getUserById = (id)             => apiRequest('get',  `/admin/users/${id}`,   {},              true);
export const createUser = (userData)        => apiRequest('post', '/admin/users',        userData         , true);
export const updateUser = (id, userData)    => apiRequest('put',  `/admin/users/${id}`,   userData         , true);
export const deleteUser = (id)              => apiRequest('delete', `/admin/users/${id}`, {},             true);

export const getVehicles = (page = 1, perPage = 10, search = '') => 
    apiRequest('get', `/admin/vehicles?page=${page}&per_page=${perPage}${search ? `&search=${search}` : ''}`, {}, true);
export const getVehicleById = (id) => apiRequest('get', `/admin/vehicles/${id}`, {}, true);
export const createVehicle = (vehicleData) => apiRequest('post', '/admin/vehicles', vehicleData, true);
export const updateVehicle = (id, vehicleData) => apiRequest('put', `/admin/vehicles/${id}`, vehicleData, true);
export const deleteVehicle = (id) => apiRequest('delete', `/admin/vehicles/${id}`, {}, true);

export const getReservations = (page = 1, perPage = 10, search = '') => 
    apiRequest('get', `/admin/reservations?page=${page}&per_page=${perPage}${search ? `&search=${search}` : ''}`, {}, true);
export const getReservationById = (id) => apiRequest('get', `/admin/reservations/${id}`, {}, true);
export const createReservation = (reservationData) => apiRequest('post', '/admin/reservations', reservationData, true);
export const updateReservation = (id, reservationData) => apiRequest('put', `/admin/reservations/${id}`, reservationData, true);
export const deleteReservation = (id) => apiRequest('delete', `/admin/reservations/${id}`, {}, true);


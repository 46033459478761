import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin';
import { loginUser, getUserProfile } from './routes/api';

export default (type, params) => {
    switch (type) {
        case AUTH_LOGIN:
            const { email, password } = params;

            return loginUser({ email, password })
                .then(({ data }) => {
                    if (data && data.user && data.user.is_admin === 1) {
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('user', JSON.stringify(data.user));
                    } else {
                        throw new Error('You are not an administrator.');
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        throw new Error(error.response.data.message);
                    } else if (error.message) {
                        throw new Error(error.message);
                    } else {
                        throw new Error('Login failed. Please try again.');
                    }
                });

        case AUTH_LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            return Promise.resolve();

        case AUTH_CHECK:
            const token = localStorage.getItem('token');
            const user = localStorage.getItem('user');

            if (!token || !user) {
                return Promise.reject({ redirectTo: '/login' });
            }

            const userId = JSON.parse(user).id;
            return getUserProfile(userId)
                .then((profileData) => {
                    if (profileData.data.user.is_admin === 1) {
                        return Promise.resolve();
                    } else {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        return Promise.reject({ redirectTo: '/login' });
                    }
                })
                .catch((error) => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    return Promise.reject({ redirectTo: '/login' });
                });

        case AUTH_ERROR:
            const status = params.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                return Promise.reject({ redirectTo: '/login' });
            }
            return Promise.resolve();

        default:
            return Promise.resolve();
    }
};

import React, { useState, useEffect } from 'react';
import { getUsers } from '../../routes/api';
import { Line } from 'react-chartjs-2';
import { TailSpin } from 'react-loader-spinner';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const UserStatsChart = () => {
    const [userStats, setUserStats] = useState({
        dates: [],
        newUsers: [],
        verifiedEmails: [],
        profileUpdates: [],
        admins: []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                let page = 1;
                let allUsers = [];
                let moreUsersAvailable = true;

                while (moreUsersAvailable) {
                    const response = await getUsers(page, 1000);
                    const users = response.data;

                    if (users.length === 0) {
                        moreUsersAvailable = false;
                    } else {
                        allUsers = [...allUsers, ...users];
                        page += 1;
                    }
                }

                const dailyStats = {};
                const emailVerifiedStats = {};
                const profileUpdateStats = {};
                const adminStats = {};

                allUsers.forEach((user) => {
                    const createdAt = user.created_at.split('T')[0];
                    const updatedAt = user.updated_at.split('T')[0];

                    dailyStats[createdAt] = (dailyStats[createdAt] || 0) + 1;

                    if (user.email_verified_at) {
                        emailVerifiedStats[createdAt] = (emailVerifiedStats[createdAt] || 0) + 1;
                    }

                    if (updatedAt !== createdAt) {
                        profileUpdateStats[updatedAt] = (profileUpdateStats[updatedAt] || 0) + 1;
                    }

                    if (user.is_admin) {
                        adminStats[createdAt] = (adminStats[createdAt] || 0) + 1;
                    }
                });

                const sortedDates = Object.keys(dailyStats).sort();
                
                const newUserCounts = sortedDates.map((date) => dailyStats[date] || 0);
                const verifiedEmailCounts = sortedDates.map((date) => emailVerifiedStats[date] || 0);
                const profileUpdateCounts = sortedDates.map((date) => profileUpdateStats[date] || 0);
                const adminCounts = sortedDates.map((date) => adminStats[date] || 0);

                setUserStats({
                    dates: sortedDates,
                    newUsers: newUserCounts,
                    verifiedEmails: verifiedEmailCounts,
                    profileUpdates: profileUpdateCounts,
                    admins: adminCounts
                });
            } catch (error) {
                console.error('Error fetching user stats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <TailSpin height="80" width="80" color="#1976d2" ariaLabel="loading" />
            </div>
        );
    }

    const data = {
        labels: userStats.dates,
        datasets: [
            {
                label: 'New Users',
                data: userStats.newUsers,
                fill: false,
                borderColor: '#1976d2',
                tension: 0.1,
            },
            {
                label: 'Email Verified Users',
                data: userStats.verifiedEmails,
                fill: false,
                borderColor: '#4caf50',
                tension: 0.1,
            },
            {
                label: 'Profile Updates',
                data: userStats.profileUpdates,
                fill: false,
                borderColor: '#ff9800',
                tension: 0.1,
            },
            {
                label: 'Admin Users',
                data: userStats.admins,
                fill: false,
                borderColor: '#f44336',
                tension: 0.1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'User Statistics per Day',
            },
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date',
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 0,
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Users',
                },
                min: 0,
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default UserStatsChart;

import React, { useState, useEffect } from 'react';
import { FunctionField, List, Datagrid, TextField, DateField, Button, Filter, TextInput, SimpleForm, TextInput as CreateTextInput, useNotify } from 'react-admin';
import { Card, CardContent, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MuiTextField } from '@mui/material';
import { getVehicles, createVehicle, updateVehicle, deleteVehicle } from '../../routes/api';

const VehicleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const VehicleList = () => {
    const notify = useNotify();
    const [vehicles, setVehicles] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [updatedBrand, setUpdatedBrand] = useState('');
    const [updatedModel, setUpdatedModel] = useState('');
    const [updatedType, setUpdatedType] = useState('');
    const [updatedStatus, setUpdatedStatus] = useState('');
    const [updatedLocation, setUpdatedLocation] = useState('');
    const [updatedPricePerDay, setUpdatedPricePerDay] = useState('');

    useEffect(() => {
        getVehicles()
            .then((data) => {
                setVehicles(data);
            })
            .catch((error) => {
                notify('Error fetching vehicles', 'error');
            });
    }, [notify]);

    const handleCreateVehicle = async (vehicleData) => {
        try {
            await createVehicle(vehicleData);
            notify('Vehicle created successfully!', 'success');
            getVehicles().then((data) => {
                setVehicles(data);
            });
        } catch (error) {
            console.log(error);
            notify(error.response.data.message, 'error');
        }
    };

    const handleUpdateVehicle = async () => {
        if (selectedVehicle) {
            try {
                const vehicleUpdateData = {
                    brand: updatedBrand,
                    model: updatedModel,
                    type: updatedType,
                    status: updatedStatus,
                    location: updatedLocation,
                    price_per_day: updatedPricePerDay,
                };

                await updateVehicle(selectedVehicle.id, vehicleUpdateData);
                notify('Vehicle updated successfully!', 'success');
                getVehicles().then((data) => {
                    setVehicles(data);
                });
                setOpenEditDialog(false);
            } catch (error) {
                notify('Error updating vehicle', 'error');
            }
        }
    };

    const handleDeleteVehicle = async () => {
        if (selectedVehicle) {
            try {
                await deleteVehicle(selectedVehicle.id);
                notify('Vehicle deleted successfully!', 'success');
                setOpenDeleteDialog(false);
                getVehicles().then((data) => {
                    setVehicles(data);
                });
            } catch (error) {
                notify('Error deleting vehicle', 'error');
            }
        }
    };

    const openEditModal = (vehicle) => {
        setSelectedVehicle(vehicle);
        setUpdatedBrand(vehicle.brand);
        setUpdatedModel(vehicle.model);
        setUpdatedType(vehicle.type);
        setUpdatedStatus(vehicle.status);
        setUpdatedLocation(vehicle.location);
        setUpdatedPricePerDay(vehicle.price_per_day);
        setOpenEditDialog(true);
    };

    const closeEditModal = () => {
        setOpenEditDialog(false);
    };

    const openDeleteModal = (vehicle) => {
        setSelectedVehicle(vehicle);
        setOpenDeleteDialog(true);
    };

    const closeDeleteModal = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <>
            <h1>Vehicles</h1>
            <Card style={{ marginBottom: '20px' }}>
                <CardContent>
                    <Typography variant="h6">Search Vehicles</Typography>
                    <List filters={<VehicleFilter />}>
                        <Datagrid rowClick="edit">
                            <TextField source="id" label="ID" />
                            <TextField source="brand" label="Brand" />
                            <TextField source="model" label="Model" />
                            <TextField source="type" label="Type" />
                            <TextField source="status" label="Status" />
                            <TextField source="location" label="Location" />
                            <TextField source="price_per_day" label="Price Per Day" />
                            <DateField 
                                source="created_at" 
                                label="Created At" 
                                showTime
                            />
                            <DateField 
                                source="updated_at" 
                                label="Updated At" 
                                showTime
                            />
                            <FunctionField
                                label="Actions"
                                render={record => (
                                    <div>
                                        <Button
                                            color="primary"
                                            size="small"
                                            style={{ marginRight: 10 }}
                                            onClick={() => openEditModal(record)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            color="secondary"
                                            size="small"
                                            onClick={() => openDeleteModal(record)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                )}
                            />
                        </Datagrid>
                    </List>
                </CardContent>
            </Card>

            <Dialog open={openEditDialog} onClose={closeEditModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Vehicle</DialogTitle>
                <DialogContent>
                    <MuiTextField
                        margin="dense"
                        label="Brand"
                        fullWidth
                        value={updatedBrand}
                        onChange={(e) => setUpdatedBrand(e.target.value)}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Model"
                        fullWidth
                        value={updatedModel}
                        onChange={(e) => setUpdatedModel(e.target.value)}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Type"
                        fullWidth
                        value={updatedType}
                        onChange={(e) => setUpdatedType(e.target.value)}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Status"
                        fullWidth
                        value={updatedStatus}
                        onChange={(e) => setUpdatedStatus(e.target.value)}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Location"
                        fullWidth
                        value={updatedLocation}
                        onChange={(e) => setUpdatedLocation(e.target.value)}
                    />
                    <MuiTextField
                        margin="dense"
                        label="Price per Day"
                        fullWidth
                        value={updatedPricePerDay}
                        onChange={(e) => setUpdatedPricePerDay(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateVehicle} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeleteDialog} onClose={closeDeleteModal} aria-labelledby="delete-dialog-title">
                <DialogTitle id="delete-dialog-title">Delete Vehicle</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete this vehicle?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteVehicle} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Card>
                <CardContent>
                    <Typography variant="h6">Create New Vehicle</Typography>
                    <SimpleForm onSubmit={handleCreateVehicle}>
                        <CreateTextInput source="brand" label="Brand" fullWidth />
                        <CreateTextInput source="model" label="Model" fullWidth />
                        <CreateTextInput source="type" label="Type" fullWidth />
                        <CreateTextInput source="status" label="Status" fullWidth />
                        <CreateTextInput source="location" label="Location" fullWidth />
                        <CreateTextInput source="price_per_day" label="Price per Day" fullWidth />
                        <CreateTextInput source="image" label="Image URL" fullWidth />
                        <CreateTextInput source="description" label="Description" fullWidth multiline />
                    </SimpleForm>
                </CardContent>
            </Card>

        </>
    );
};

export default VehicleList;


import React from 'react';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { FaUser, FaCar, FaClipboardList } from 'react-icons/fa';

import Dashboard from './components/Dashboard';
import UserList from './components/lists/UserList';
import VehicleList from './components/lists/VehicleList';
import ReservationList from './components/lists/ReservationList';
import VehicleKanban from './components/kanban/VehicleKanban';

import authProvider from './authProvider';
import MyLoginPage from './MyLoginPage';

import dataProvider from './services/dataProvider';

import 'leaflet/dist/leaflet.css';

const App = () => (
    <Admin
        loginPage={MyLoginPage} authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard} 
    >
        <Resource name="users" list={UserList} icon={() => <FaUser />} />
        <Resource name="vehicles" list={VehicleList} icon={() => <FaCar />} />
        <Resource name="reservations" list={ReservationList} icon={() => <FaClipboardList />} />
        <Resource name="kanban" list={VehicleKanban} icon={() => <FaClipboardList />} />
    </Admin>
);

export default App;

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { getVehicles } from '../../routes/api'; 
import { Icon } from 'leaflet';

const locationCoords = {
  "Podgorica": [42.4411, 19.2636],
  "Niksic": [42.7797, 18.9604],
  "Bijelo Polje": [43.0275, 19.1355],
  "Herceg Novi": [42.4581, 18.5316],
  "Tivat": [42.4341, 18.7235],
  "Bar": [42.1043, 19.0827],
  "Ulcinj": [41.9220, 19.2090],
};

const VehicleMap = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cityCounts, setCityCounts] = useState({});

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await getVehicles();
        const vehicleData = response.data;

        const processedVehicles = vehicleData.map(vehicle => ({
          id: vehicle.id,
          brand: vehicle.brand,
          model: vehicle.model,
          location: vehicle.location,
          latitude: vehicle.latitude,
          longitude: vehicle.longitude,
          image: vehicle.image,
          pricePerDay: vehicle.price_per_day,
        }));

        setVehicles(processedVehicles);

        const cityMap = processedVehicles.reduce((acc, vehicle) => {
          const city = vehicle.location;
          if (city) {
            acc[city] = (acc[city] || 0) + 1;
          }
          return acc;
        }, {});

        setCityCounts(cityMap);
      } catch (error) {
        console.error("Error fetching vehicle data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, []);

  const vehicleIcon = new Icon({
    iconUrl: "/redmark.png",
    iconSize: [30, 30],
    popupAnchor: [0, -10],
  });

  const generateCityIcon = (city) => {
    return L.divIcon({
      html: `<div style="display: flex; justify-content: center; align-items: center; 
                    background-color: #00ff00; border-radius: 50%; width: 40px; height: 40px; 
                    color: white; font-weight: bold;">${cityCounts[city]}</div>`,
      className: '',
      iconSize: [40, 40],
      popupAnchor: [0, -10],
    });
  };

  if (loading) {
    return <p>Loading vehicles...</p>;
  }

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <MapContainer center={[42.4411, 19.2636]} zoom={8} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {Object.keys(cityCounts).map((city) => {
          const coords = locationCoords[city];

          if (coords) {
            return (
              <Marker key={city} position={coords} icon={generateCityIcon(city)}>
                <Popup>
                  <h3>{city}</h3>
                  <p><strong>Number of Vehicles:</strong> {cityCounts[city]}</p>
                </Popup>
              </Marker>
            );
          }
          return null;
        })}

        {vehicles.map((vehicle) => {
          const coords = vehicle.latitude && vehicle.longitude ? [vehicle.latitude, vehicle.longitude] : locationCoords[vehicle.location];

          if (coords) {
            return (
              <Marker key={vehicle.id} position={coords} icon={vehicleIcon}>
                <Popup>
                  <img src={vehicle.image} alt={vehicle.model} style={{ width: '100px', height: 'auto' }} />
                  <div>
                    <h3>{vehicle.brand} {vehicle.model}</h3>
                    <p><strong>Price per day:</strong> {vehicle.pricePerDay} €</p>
                    <p><strong>Location:</strong> {vehicle.location}</p>
                  </div>
                </Popup>
              </Marker>
            );
          }
          return null;
        })}
      </MapContainer>
    </div>
  );
};

export default VehicleMap;

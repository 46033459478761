import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { getVehicles, updateVehicle } from '../../routes/api';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { StrictModeDroppable } from './StrictModeDroppable'; // import the custom Droppable

const VehicleKanban = () => {
    const [vehicles, setVehicles] = useState({
        available: [],
        rented: [],
        maintenance: [],
    });

    const fetchVehicles = async () => {
        try {
            const response = await getVehicles(1, 100);
            const vehiclesData = response.data;

            const available = vehiclesData.filter((vehicle) => vehicle.status === 'available');
            const rented = vehiclesData.filter((vehicle) => vehicle.status === 'rented');
            const maintenance = vehiclesData.filter((vehicle) => vehicle.status === 'maintenance');

            setVehicles({
                available,
                rented,
                maintenance,
            });
        } catch (error) {
            console.error('Error fetching vehicles:', error);
        }
    };

    useEffect(() => {
        fetchVehicles();
    }, []);

    const onDragEnd = async (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const sourceList = [...vehicles[source.droppableId]];
        const destList = [...vehicles[destination.droppableId]];
        const [movedVehicle] = sourceList.splice(source.index, 1);
        destList.splice(destination.index, 0, movedVehicle);

        // Avoid setting state when no changes happen
        if (JSON.stringify(vehicles[source.droppableId]) === JSON.stringify(sourceList) &&
            JSON.stringify(vehicles[destination.droppableId]) === JSON.stringify(destList)) {
            return;
        }

        setVehicles({
            ...vehicles,
            [source.droppableId]: sourceList,
            [destination.droppableId]: destList,
        });

        try {
            await updateVehicle(movedVehicle.id, { status: destination.droppableId });
        } catch (error) {
            console.error('Error updating vehicle status:', error);
        }
    };

    const renderColumn = (status, title) => (
        <Grid item xs={12} sm={4} key={status}>
            <h3>{title}</h3>
            <StrictModeDroppable droppableId={status}>
                {(provided) => {
                    const { droppableProps, innerRef } = provided || {};

                    return (
                        <div
                            {...droppableProps}
                            ref={innerRef}
                            style={{
                                backgroundColor: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '8px',
                                minHeight: '300px',
                            }}
                        >
                            {/* Render vehicles or fallback if none */}
                            {vehicles[status]?.length > 0 ? (
                                vehicles[status].map((vehicle, index) => (
                                    <Draggable key={vehicle.id} draggableId={`${vehicle.id}`} index={index}>
                                        {(provided) => {
                                            const { innerRef, draggableProps, dragHandleProps } = provided || {};
                                            return (
                                                <div
                                                    ref={innerRef}
                                                    {...draggableProps}
                                                    {...dragHandleProps}
                                                    style={{
                                                        padding: '10px',
                                                        margin: '0 0 8px 0',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '4px',
                                                        ...draggableProps.style,
                                                    }}
                                                >
                                                    <Card>
                                                        <CardContent>
                                                            <Typography variant="h6">
                                                                {vehicle.brand} {vehicle.model}
                                                            </Typography>
                                                            <Typography>Status: {vehicle.status}</Typography>
                                                            <Typography>Location: {vehicle.location}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                ))
                            ) : (
                                <Typography>No vehicles available</Typography>
                            )}
                            {provided.placeholder}
                        </div>
                    );
                }}
            </StrictModeDroppable>
        </Grid>
    );

    return (
        <div>
            <h1>Vehicle Kanban Board</h1>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container spacing={3}>
                    {['available', 'rented', 'maintenance'].map((status) => renderColumn(status, status.charAt(0).toUpperCase() + status.slice(1)))}
                </Grid>
            </DragDropContext>
        </div>
    );
};

export default VehicleKanban;

